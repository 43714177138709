import { forwardRef } from 'react';
import { compose } from 'redux';

import { EditButton } from 'components/_shared/buttons';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import { createStyles, withStyles } from '@mui/styles';

const styles = () =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1
        },
        input: {
            backgroundColor: '#FFFFFF',
            border: 0,
            borderRadius: 6,
            fontFamily: 'Manrope',
            fontSize: '0.9375rem',
            fontWeight: 500,
            height: '3.125rem',
            letterSpacing: -0.21,
            lineHeight: '1.3125rem',
            padding: '0 10px',
            resize: 'none',
            width: '100%',
            '&::placeholder': {
                color: '#1F2051',
                opacity: 0.4
            }
        },
        values: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Manrope',
            fontSize: '24px',
            fontWeight: 700,
            height: '3.125rem',
            letterSpacing: -0.21,
            lineHeight: '1.3125rem'
        }
    });

type FormInputProps = {
    autoComplete?: string;
    classes: {
        input: string;
        root: string;
        values: string;
    };
    className?: string;
    customStep?: boolean;
    disabled?: boolean;
    id?: string;
    isEditing?: {
        [key: string]: boolean;
    };
    name: string;
    onBlur?: () => void;
    onFocus?: () => void;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    ref: React.Ref<HTMLTextAreaElement>;
    saveCustomInput?: (cost: number, date: number, description: string) => void;
    saveInput?: () => void;
    setIsEditing?: any;
    template?: boolean;
    value: any;
};

const FormInput = forwardRef<HTMLTextAreaElement, FormInputProps>(
    function FormInput({ classes, ...rest }, ref) {
        const {
            customStep,
            name,
            isEditing,
            saveCustomInput,
            saveInput,
            setIsEditing,
            template,
            value,
            ...inputProps
        } = rest;

        const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (customStep && template && e.key === 'Enter') {
                e.preventDefault();

                if (saveCustomInput) {
                    saveCustomInput(0, 0, value);
                }
            }

            if (e.key === 'Enter' && saveInput) {
                saveInput();
            }
        };

        const handleEditClick = () => {
            if (isEditing && setIsEditing) {
                setIsEditing((prev: { [key: string]: boolean }) => ({
                    ...prev,
                    [name]: true
                }));
            }

            if (inputProps.onClick) {
                inputProps.onClick();
            }
        };

        return (
            <div className={classes.root}>
                {!customStep &&
                isEditing &&
                !isEditing[name] &&
                template &&
                value ? (
                    <>
                        <span className={classes.values}>{value}</span>
                        {!isEditing[name] && (
                            <EditButton
                                handleEditClick={handleEditClick}
                                inputValState={value}
                            />
                        )}
                    </>
                ) : (
                    <TextareaAutosize
                        className={classes.input}
                        id={name}
                        onKeyDown={handleKeyDown}
                        {...inputProps}
                        ref={ref}
                        value={value}
                    />
                )}
            </div>
        );
    }
);

export default compose(withStyles(styles))(FormInput);
