import Card from '../../../_shared/Card';
import ActionStepForm from './ActionStepForm';

import iconEdit from 'assets/icon-edit.png';
import iconClock from 'assets/icon-clock.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    needsTitle: {
        position: 'absolute',
        left: '20px',
        top: '-12px',
        backgroundColor: '#8882D8',
        borderRadius: '100px',
        color: '#fff',
        fontWeight: '800',
        letterSpacing: '0.1em',
        padding: '0.25rem 1rem',
        textTransform: 'uppercase'
    },
    needInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '5px',
        padding: '10px 0 0 0'
    },
    needDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 0 10px 0',
        '& div': {
            margin: '0 10px 0 0'
        }
    },
    needType: {
        fontSize: '24px',
        fontWeight: 700
    },
    needCost: {
        backgroundColor: '#EAEBFB',
        borderRadius: '100px',
        fontWeight: 700,
        padding: '5px 15px'
    },
    needDate: {
        fontWeight: 'bold'
    },
    needGoalsContainer: {
        display: 'flex'
    },
    needGoal: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#EAEBFB',
        borderRadius: '100px',
        fontWeight: 'bold',
        margin: '0 5px 0 0',
        padding: '5px 15px',
        whiteSpace: 'nowrap'
    },
    needDateAndGoals: {
        display: 'flex'
    },
    immediateNeedsWrapper: {
        position: 'relative',
        borderRadius: '20px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '20px',
        width: '90%'
    },
    needStack1: {
        position: 'relative',
        top: '-16%',
        zIndex: '-1'
    },
    needStack2: {
        position: 'relative',
        top: '-32%',
        zIndex: '-2'
    },
    needStack: {
        display: 'none'
    },
    dueDateContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    iconContainer: {
        display: 'flex',
        '& img': {
            height: '25px'
        },
        '& #iconEdit': {
            cursor: 'pointer'
        }
    },
    editIconContainer: {
        position: 'absolute',
        top: '35%',
        right: '-25px',
        display: 'flex',
        background: '#fff',
        borderRadius: '100px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '12px',
        '& img': {
            height: '20px'
        },
        '& #iconEdit': {
            cursor: 'pointer'
        },
        '&:active': {
            transform: 'scale(0.95)'
        }
    }
}));

type ActionStepSummaryProps = {
    actionSuggestions: any;
    actionType: any[];
    editAction: number[];
    from: string;
    intakeGoals: any[];
    remainingBonusAmount: number;
    setDisplayNeedBtns?: (displayNeedBtns: boolean) => void;
    setEditAction: (editAction: any[]) => void;
    setGuide: (guide: any) => void;
    setIntakeGoals: (intakeGoals: any[]) => void;
    setShowForm: (showForm: boolean) => void;
};

const ActionStepSummary: React.FC<ActionStepSummaryProps> = ({
    actionSuggestions,
    actionType,
    editAction,
    from,
    intakeGoals,
    remainingBonusAmount,
    setDisplayNeedBtns,
    setEditAction,
    setGuide,
    setIntakeGoals,
    setShowForm
}) => {
    const classes = useStyles();

    const count = actionType ? actionType.length : 0;

    const handleEditClick = (index: number) => {
        setShowForm(false);

        if (!editAction.includes(index)) {
            setEditAction([...editAction, index]);
        }
    };

    const renderDueDate = (date: number | string) => {
        const today = new Date() as any;
        let dueDate = `` as Date | string;

        if (typeof date === 'string' && date.length <= 10) {
            dueDate = new Date(date + 'T00:00:00');
        } else if (typeof date === 'string') {
            dueDate = new Date(date);
        } else {
            dueDate = new Date(today);
            dueDate.setDate(today.getDate() + date);
        }

        const diffTime = Math.abs((dueDate as any) - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays <= 3) {
            return `ASAP`;
        } else {
            return `${diffDays} Days`;
        }
    };

    return (
        <>
            {actionType.map((action, index) => {
                if (editAction.includes(index)) {
                    return (
                        <ActionStepForm
                            showEdit={true}
                            editForm={{
                                actionIndex: index,
                                costInputState: intakeGoals[index].amount,
                                customGoalInputState:
                                    intakeGoals[index].customGoals,
                                dateInputState: intakeGoals[index].due_at,
                                descriptionInput: true,
                                descriptionInputState:
                                    intakeGoals[index].description,
                                goalInputState: intakeGoals[index].goals,
                                qolMeasureState: intakeGoals[index].qolMeasure
                            }}
                            from={from}
                            key={index}
                            intakeGoals={intakeGoals}
                            setIntakeGoals={setIntakeGoals}
                            editAction={editAction}
                            setEditAction={setEditAction}
                            setShowForm={setShowForm}
                            onClick={() => setDisplayNeedBtns!(true)}
                            actionSuggestions={actionSuggestions}
                            remainingBonusAmount={remainingBonusAmount}
                            setGuide={setGuide}
                        />
                    );
                }

                return (
                    <Card key={index} className={classes.immediateNeedsWrapper}>
                        {actionType.length !== 0 && index === 0 && (
                            <span className={classes.needsTitle}>
                                {count} ACTION STEPS
                            </span>
                        )}
                        {intakeGoals[index].default !== true && (
                            <div
                                className={classes.editIconContainer}
                                data-edit={index}
                                onClick={() => handleEditClick(index)}
                            >
                                <img
                                    alt="edit-icon"
                                    id="iconEdit"
                                    src={iconEdit}
                                />
                            </div>
                        )}
                        <div className={classes.needInfo}>
                            <span className={classes.needType}>
                                {action.description}
                            </span>
                            {action.amount !== `0` &&
                            action.amount !== `0.0` &&
                            action.amount !== 0 ? (
                                <span className={classes.needCost}>
                                    ${action.amount}
                                </span>
                            ) : null}
                        </div>
                        <div className={classes.needDetails}>
                            <div className={classes.needDateAndGoals}>
                                <div className={classes.dueDateContainer}>
                                    <div className={classes.iconContainer}>
                                        <img src={iconClock} alt="clock-icon" />
                                    </div>
                                    <span className={classes.needDate}>
                                        {action.due_at ? (
                                            <>
                                                {` `}in{` `}
                                                {typeof action.due_at ===
                                                    'string' &&
                                                action.due_at.includes(`Days`)
                                                    ? action.due_at
                                                    : renderDueDate(
                                                          action.due_at
                                                      )}
                                            </>
                                        ) : (
                                            `No Due Date`
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Card>
                );
            })}
        </>
    );
};

export default ActionStepSummary;
