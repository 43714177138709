import SamaritanFetch from './httpClient';

export const fetchDeployment = (deploymentID: number) =>
    SamaritanFetch.v3(
        `/deployments/${deploymentID}?deployment[relations][organizations]{}`
    );

export const fetchDeploymentUsers = () =>
    SamaritanFetch.v3(`/deployment_users`);

export const referMember = ({
    homeless_id,
    organization_id,
    referee_id
}: {
    homeless_id: number;
    organization_id: number;
    referee_id: number;
}) =>
    SamaritanFetch.v3.post(`/ctp_caseload_referrals`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            ctp_caseload_referral: {
                homeless_id,
                organization_id,
                referee_id
            }
        })
    });
