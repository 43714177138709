import { useState } from 'react';

// import { TouchpointQuestions } from 'types/index';
import { TouchpointQuestions } from '../../../types';

import CardBackground from '../../_shared/CardBackground';
import QuestionBody from './components/QuestionBody';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        statusContainer: {
            position: 'relative',
            borderRadius: '16px',
            height: 'fit-content',
            margin: '0 auto 50px auto',
            padding: '50px 0 0 0',
            width: '60%',
            '@media (max-width: 768px)': {
                width: '90%'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '40px'
        },
        nextBtn: {
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '36px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.08)',
            color: '#FBFBFF',
            fontSize: '24px',
            fontWeight: 700,
            padding: '12px 72px',
            textTransform: 'none',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #918ED7 100%)'
            },
            '& i': {
                marginLeft: '15px'
            }
        },
        disabled: {
            background: '#E3E3FA',
            color: '#ffffff !important'
        }
    })
);

type StatusCaptureProps = {
    incrementStep: (progressBarWidth: number) => void;
    intakeTouchpoints: {
        answer: string;
        questionId: number;
    }[];
    progressBarWidth: number;
    setIntakeTouchpoints: (questionId: number, answer: string) => void;
    touchpointQuestions: TouchpointQuestions[];
};

// TODO: Remove answers from props
const StatusCapture: React.FC<StatusCaptureProps> = ({
    incrementStep,
    intakeTouchpoints,
    progressBarWidth,
    setIntakeTouchpoints,
    touchpointQuestions
}) => {
    const classes = useStyles();

    const questions = touchpointQuestions?.filter(
        (question) => question.type === 'StatusQuestion'
    );

    const [answers, setAnswers] = useState<
        {
            answer: string[];
            id: number;
        }[]
    >([]);

    const onContinue = () => {
        const container = document.getElementById('intake-container');

        if (container) {
            container.scrollTop = 0;
        }

        incrementStep(progressBarWidth);
    };

    const renderDisabledClass = () => {
        const disabled = intakeTouchpoints.length !== questions.length;

        return disabled
            ? `${classes.nextBtn} ${classes.disabled}`
            : classes.nextBtn;
    };

    return (
        <div className={classes.statusContainer}>
            <CardBackground />
            {questions.map((question, index) => (
                <QuestionBody
                    key={index}
                    answers={answers}
                    intakeTouchpoints={intakeTouchpoints}
                    questions={question}
                    questionLength={questions.length}
                    questionNum={index}
                    setAnswers={setAnswers}
                    setIntakeTouchpoints={setIntakeTouchpoints}
                />
            ))}
            <div className={classes.buttonContainer}>
                <Button
                    className={renderDisabledClass()}
                    disabled={intakeTouchpoints.length !== questions.length}
                    onClick={() => onContinue()}
                >
                    Next
                    <i className="fas fa-chevron-right" />
                </Button>
            </div>
        </div>
    );
};

export default StatusCapture;
