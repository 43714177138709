import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import { postRedemption } from '../../../../api/redemption';
import { getHomelessInfo } from '../../../../redux/actions/homeless';
import { setRedeemStatus } from '../../../../redux/actions/unenrollment';
import { completeItem } from '../../../../redux/actions/catch_up_items';

import BeaconHolderBalance from './components/BeaconHolderBalance';
import TemplateList from './components/TemplateList';
import Form from './components/FormContainer';
import RedemptionConfirmation from './components/Confirmation';
import RedeemDebrief from './components/RedeemDebrief';

import LoadingCircle from '../../../_shared/LoadingCircle';
import ErrorModal from '../../../_shared/RequestErrorModal';
import Modal from '../../../_shared/Modal';

import Button from '@mui/material/Button';

import classes from './index.module.scss';

/**
 * Redemption stages:
 * 0: "LIST"
 * 1: "FORM"
 * 2: "CONFIRM"
 * 3: "SUBMITTING"
 * 4: "DEBRIEF"
 */

function Redeem({ beaconId, dispatch, homelessId, templates }) {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const [stage, setStage] = useState(0);
    const [templateId, setTemplateId] = useState(null);
    const [formAnswers, setFormAnswers] = useState({});
    const [remainingBalance, setRemainingBalance] = useState(null);
    const [transactionId, setTransactionId] = useState('');
    const [errorThings, setErrorThings] = useState({});

    const [isModalOpen, setModalState] = useState(false);
    const [isErrorModalOpen, setErrorModalState] = useState(false);

    const containerRef = useRef(null);

    useEffect(() => {
        // scroll to top every time the stage changes.
        if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
        }
    }, [stage]);

    // NOTICE: removing this useEffect and instead
    // setting stage on mount *will* break edit behavior
    useEffect(() => {
        if (location.state && location.state.initialState) {
            setFormAnswers(location.state.initialState);
            setStage(2);
        }
    }, [location.state]);

    /**
     * set template id when editing when navigate from reorder button
     */
    useEffect(() => {
        if (
            stage === 1 &&
            location.state &&
            location.state.initialState &&
            templates.length
        ) {
            /**
             * This only works because the initial stage when navigating direct to
             * confirmation is stage === 0 which then gets set to 2 when useEffect
             * triggers update. initial stage 0 render triggers fetch for templates.
             */
            const initialState = location.state.initialState;
            const matchedTemplate = templates.find(
                ({ template }) =>
                    template.title === initialState.title &&
                    template.category === initialState.category
            );
            if (matchedTemplate)
                setTemplateId(matchedTemplate.template.backend_id);
        }
    }, [location.state, stage, templates]);

    /*
     * closeView shows a warning dialog before navigating out.
     * warn argument controls whether or not its shown.
     * shows by default.
     */
    const closeView = useCallback(
        (warn = true) => {
            let close = true;

            // dispatch to get homeless data
            // bypass everything else because it doesn't matter anymore
            // dispatch will reset profile state and close this view.
            if (stage === 4) dispatch(getHomelessInfo('', '', '', homelessId));

            if (warn) {
                setModalState(true);
                close = false;
            }

            // shouldn't be able to navigate during submission.
            if (stage === 3) return;

            if (close) {
                setModalState(false);
                const { from } = history.location.state || {};

                if (from && from !== 'exitRedeem' && from !== 'catchUp') {
                    history.push(`${match.url}`.replace(/\/redeem$/, ''), {
                        from: 'redeem'
                    });
                } else if (from && from === 'catchUp') {
                    history.push(`${match.url}`.replace(/\/redeem$/, ''), {
                        from: 'catchUp'
                    });
                } else {
                    history.push(`${match.url}`.replace(/\/redeem$/, ''), {
                        from: 'exitRedeem'
                    });
                }
            }
        },
        [stage, dispatch, history, match.url, homelessId]
    );

    const goBack = useCallback(() => {
        if (stage === 0) return closeView();
        // shouldn't be able to navigate during submission.
        if (stage === 3) return;

        setStage(stage - 1);
    }, [stage, closeView]);

    const onTemplateSelect = useCallback(
        (id) => {
            // clear cached form answers if navigating to a new templateId
            if (templateId !== id) setFormAnswers({});
            setTemplateId(id);
            setStage(1);
        },
        [templateId]
    );

    const onAnswersCollected = useCallback((answers) => {
        setFormAnswers(answers);
        setStage(2);
    }, []);

    const onAnswersConfirm = useCallback(async () => {
        setErrorModalState(false);
        try {
            setStage(3);
            let res = await postRedemption(beaconId, formAnswers);
            let resJson = await res.json();

            if (res && !res.ok) {
                setErrorThings({
                    message: resJson.errors,
                    retryFunc: onAnswersConfirm
                });
                setErrorModalState(true);
            } else {
                if (location.state && location.state.from === 'catchUp') {
                    dispatch(completeItem(location.state.id));
                }

                setTransactionId(resJson.redeem_transaction_id);
                setRemainingBalance(resJson.beacon_holder.balance);
                setStage(4);
            }
        } catch (e) {
            setErrorThings({
                message: e.errors,
                retryFunc: onAnswersConfirm
            });
            setErrorModalState(true);
        }
    }, [beaconId, dispatch, formAnswers, location.state]);

    let chevronTitle = 'Profile';

    switch (stage) {
        case 1:
            chevronTitle = 'Forms';
            break;
        case 2:
            chevronTitle = 'Edit Form';
            break;
        default:
            break;
    }

    if (stage === 1 && templateId === null) return <LoadingCircle />;

    return (
        <div className={classes.container} ref={containerRef}>
            {/* hide this header on debrief */}
            {stage !== 4 && stage !== 3 && (
                <BeaconHolderBalance
                    goBack={goBack}
                    chevronTitle={chevronTitle}
                />
            )}
            <Modal open={isModalOpen} onClose={() => setModalState(false)}>
                <div style={{ padding: 20 }}>
                    <div className="font-lg">
                        Are you sure you want to leave the redemption form? Your
                        progress will be lost.
                    </div>
                    <div className={classes.btnContainer}>
                        <Button
                            className={classes.btn}
                            onClick={() => closeView(false)}
                        >
                            Yes
                        </Button>
                        <Button
                            className={`${classes.btn} ${classes.btnCancel}`}
                            onClick={() => setModalState(false)}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Modal>
            <ErrorModal
                onClose={() => {
                    setErrorModalState(false);
                    setStage(2);
                }}
                message={errorThings.message}
                retryFunc={errorThings.retryFunc}
                open={isErrorModalOpen}
            />
            {stage === 0 && <TemplateList onSelect={onTemplateSelect} />}
            {stage === 1 && (
                <Form
                    templateId={templateId}
                    onSubmit={onAnswersCollected}
                    initialState={formAnswers}
                />
            )}
            {stage === 2 && (
                <RedemptionConfirmation
                    formAnswers={formAnswers}
                    goBack={goBack}
                    onConfirm={onAnswersConfirm}
                    onCancel={closeView}
                />
            )}
            {stage === 3 && <LoadingCircle>Processing Payment</LoadingCircle>}
            {stage === 4 && (
                <RedeemDebrief
                    amount={Number(formAnswers.amount)}
                    category={formAnswers.category}
                    title={formAnswers.title}
                    closeView={() => {
                        // this dispatch triggers a remount of the profile component
                        // and will "automatically" close this component by resetting state
                        // dispatch(getHomelessInfo('', '', '', homelessId));

                        if (!history.location.state) {
                            history.push(
                                `${match.url}`.replace(/\/redeem$/, ''),
                                {
                                    from: 'redeem',
                                    success: true
                                }
                            );
                        } else {
                            if (
                                history.location.state &&
                                history.location.state.from === 'exitRedeem'
                            ) {
                                dispatch(setRedeemStatus(true));
                            } else if (
                                history.location.state &&
                                history.location.state.from === 'catchUp'
                            ) {
                                history.push(
                                    `${match.url}`.replace(/\/redeem$/, '', {
                                        from: 'catchUp'
                                    })
                                );
                            } else {
                                history.push(
                                    `${match.url}`.replace(/\/redeem$/, ''),
                                    {
                                        from: 'exitRedeem',
                                        success: true
                                    }
                                );
                            }
                        }
                    }}
                    remainingBalance={remainingBalance}
                    transactionId={transactionId}
                />
            )}
        </div>
    );
}

Redeem.propTypes = {
    beaconId: PropTypes.string.isRequired,
    homelessId: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  templates: state.redemption.redemptionList,
});

export default connect(mapStateToProps)(Redeem);
