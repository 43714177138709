import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Caseload, Homeless } from 'types';

import Card from 'components/_shared/Card';
import LoadingOverlay from './LoadingOverlay';
import ReferralTag from 'components/_shared/ReferralTag';
import UserReferral from './UserReferral';
import { CTAButton3 } from 'components/_shared/buttons';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            backgroundColor: '#EBEBFF !important',
            padding: '0 !important'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FBFBFF',
            borderRadius: '0 0 16px 16px',
            padding: '16px 24px'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            margin: '24px 0 0 0',
            padding: '24px 8px 0 8px'
        },
        head: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            padding: '56px 16px 16px 16px'
        },
        name: {
            fontSize: '24px',
            fontWeight: 700
        },
        nameContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        photo: {
            borderRadius: '50%',
            height: '150px',
            width: '150px',
            objectFit: 'cover'
        },
        profileButton: {
            borderRadius: '36px',
            color: '#7378E8',
            fontSize: '18px',
            fontWeight: 700,
            letterSpacing: '0.44px',
            textTransform: 'none',
            '& i': {
                marginLeft: '8px'
            }
        }
    })
);

type ReferCardProps = {
    handleCloseClick: () => void;
    referCaseload: Caseload;
    referHolder: Homeless;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (
        caseloadID: number,
        memberID: number,
        accepted?: boolean
    ) => void;
};

const ReferCard: React.FC<ReferCardProps> = ({
    handleCloseClick,
    referCaseload,
    referHolder,
    removeMemberFromMyMembers,
    saveMemberToMyMembers
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { id: caseload_id, latest_referral = null } = referCaseload;
    const { first_name, id: holder_id, last_name, photo } = referHolder;

    const [loading, setLoading] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [rejected, setRejected] = useState(false);

    const handleViewProfile = () => {
        history.push(`/holders/${referHolder.id}`);
    };

    const handleActionWithDelay = async (
        actionFunction: any,
        successCallback: () => void
    ) => {
        setLoading(true);

        await new Promise((resolve) => {
            actionFunction();
            setTimeout(resolve, 1000);
        });

        setLoading(false);
        successCallback();
        setTimeout(handleCloseClick, 2000);
    };

    const handleRejectClick = async () => {
        await handleActionWithDelay(
            () => removeMemberFromMyMembers(caseload_id, true),
            () => {
                setRejected(true);
            }
        );
    };

    const handleAcceptClick = async () => {
        await handleActionWithDelay(
            () => saveMemberToMyMembers(caseload_id, holder_id, true),
            () => {
                setAccepted(true);
            }
        );
    };

    return (
        <Card className={classes.card}>
            {(accepted || loading || rejected) && (
                <LoadingOverlay
                    accepted={accepted}
                    loading={loading}
                    rejected={rejected}
                />
            )}
            <div className={classes.head}>
                <ReferralTag text="referred to you" />
                <img className={classes.photo} src={photo} alt="profile" />
                <div className={classes.nameContainer}>
                    <span className={classes.name}>
                        {first_name} {last_name}
                    </span>
                    <Button
                        className={classes.profileButton}
                        onClick={handleViewProfile}
                    >
                        View Profile <i className="fas fa-chevron-right" />
                    </Button>
                </div>
            </div>
            <div className={classes.body}>
                <UserReferral
                    name={latest_referral?.referrer.name || ''}
                    org={latest_referral?.referrer.organization_name || ''}
                    date={latest_referral?.created_at || ''}
                />
                <div className={classes.buttonContainer}>
                    <CTAButton3 onClick={handleAcceptClick}>
                        Accept Referral
                    </CTAButton3>
                    <CTAButton3 invert onClick={handleRejectClick}>
                        Reject Referral
                    </CTAButton3>
                </div>
            </div>
        </Card>
    );
};

export default ReferCard;
