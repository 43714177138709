import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { updateUserSettings } from '../../../../../redux/actions/user';
// import { updateHomelessLanguagePreference } from '../../../../../redux/actions/homelesses';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        languagePopup: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '460px'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            backgroundColor: '#EDEDFA',
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 800,
            padding: '24px 0',
            width: '100%'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            gap: '4px',
            padding: '36px 36px 24px 36px',
            minWidth: '80%'
        },
        languageSelector: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#1F2152',
            fontSize: '20px',
            fontWeight: 600,
            gap: '100px',
            padding: '10px 20px',
            width: '100%'
        },
        subtitle: {
            fontSize: '16px',
            fontWeight: 300
        },
        languageItem: {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px'
        },
        selectorBtn: {
            color: '#A6A6BF',
            borderRadius: '50%',
            fontSize: '24px',
            minWidth: 'auto',
            padding: '0'
        },
        saveBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#7378E8',
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '16px',
            fontWeight: 800,
            lineHeight: '20px',
            margin: '20px auto',
            padding: '16px 40px',
            textTransform: 'none',
            width: '60%',
            '& i': {
                fontSize: '20px',
                marginRight: '12px'
            },
            '&:hover': {
                color: '#7378E8'
            }
        }
    })
);

type Props = {
    homelessInfo?: any;
    homelessLanguages?: string[];
    id: number;
    handleCloseClick: () => void;
    languages: string[];
    languagePreference: string;
    privateInfo?: boolean;
    setNewLanguage: (value: string) => void;
};

const Language: React.FC<Props> = ({
    homelessInfo,
    homelessLanguages,
    id,
    handleCloseClick,
    languages,
    languagePreference,
    privateInfo,
    setNewLanguage
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { language_preference } = homelessInfo ?? '';

    const [homelessLanguage, setHomelessLanguage] =
        useState(language_preference);
    const [userLanguage, setUserLanguage] = useState(
        languagePreference && languagePreference.length
            ? languagePreference
            : ''
    );

    const handleSaveClick = () => {
        if (privateInfo) {
            setNewLanguage(homelessLanguage);
            // dispatch(
            //     updateHomelessLanguagePreference(homelessId, homelessLanguage)
            // );
        } else {
            dispatch(
                updateUserSettings(id, {
                    languagePreference: userLanguage
                })
            );
        }

        handleCloseClick();
    };

    const renderDisabledSaveButton = () => {
        if (!privateInfo) {
            return !userLanguage.length;
        } else {
            return !homelessLanguage.length;
        }
    };

    const renderLanguageDescription = (language: string) => {
        let title;
        let subtitle;

        switch (language) {
            case 'english':
                title = 'English';
                subtitle = privateInfo
                    ? '"You have received a $10 donation"'
                    : '"Congratulations on signing up a new Samaritan member!"';
                return { title, subtitle };
            case 'spanish':
                title = 'Español';
                subtitle = privateInfo
                    ? '"Ha recibido una donación de $10"'
                    : '“¡Felicidades por registrarse como nuevo miembro samaritano!”';
                return { title, subtitle };
            default:
                title = language && language.toUpperCase();
                return { title };
        }
    };

    const renderLanguageOptions = () => {
        if (!privateInfo) {
            return languages;
        } else {
            return homelessLanguages ?? [];
        }
    };

    const renderLanguageSelection = (language: string) => {
        if (!privateInfo) {
            return userLanguage === language;
        } else {
            return homelessLanguage === language;
        }
    };

    return (
        <div className={classes.languagePopup}>
            <span className={classes.header}>Language Preference</span>
            <div className={classes.body}>
                {renderLanguageOptions().map((language, index) => {
                    return (
                        <div className={classes.languageSelector} key={index}>
                            <div className={classes.languageItem}>
                                <span>
                                    {renderLanguageDescription(language).title}
                                </span>
                                <span className={classes.subtitle}>
                                    {
                                        renderLanguageDescription(language)
                                            .subtitle
                                    }
                                </span>
                            </div>
                            <Button
                                className={classes.selectorBtn}
                                onClick={() =>
                                    !privateInfo
                                        ? setUserLanguage(language)
                                        : setHomelessLanguage(language)
                                }
                            >
                                {renderLanguageSelection(language) ? (
                                    <i
                                        className="fas fa-check-circle"
                                        style={{ color: '#7378E8' }}
                                    />
                                ) : (
                                    <i className="fal fa-circle" />
                                )}
                            </Button>
                        </div>
                    );
                })}
            </div>
            <Button
                className={classes.saveBtn}
                disabled={renderDisabledSaveButton()}
                onClick={() => handleSaveClick()}
                style={
                    renderDisabledSaveButton()
                        ? { color: '#FFFFFF', opacity: 0.5 }
                        : {}
                }
            >
                Save
            </Button>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        homelessLanguages: state.homelesses.languages,
        id: state.user.user.id,
        languagePreference: state.user.user.language_preference,
        languages: state.user.languages
    };
};

export default connect(mapStateToProps)(Language);
