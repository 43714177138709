import { useState } from 'react';

import { ActionStepTemplate } from 'types/index';

import Card from 'components/_shared/Card';
import TemplateCard from './TemplateCard';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    actionTemplateCard: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '90%'
    },
    customTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        color: '#A6A6BF',
        '& span:first-child': {
            flex: '0 1 auto'
        }
    },
    divider: {
        flex: '1 1 auto',
        backgroundColor: '#A6A6BF',
        borderRadiuis: '2px',
        height: '1px'
    },
    expandButton: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        gap: '8px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        boxShadow: '0px 8px 16px 4px #0000000D',
        color: '#A6A6BF',
        fontWeight: 600,
        padding: '10px 20px',
        textTransform: 'none',
        width: 'fit-content'
    },
    templateContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px 0'
    },
    templateTitle: {
        position: 'absolute',
        left: '20px',
        top: '-16px',
        backgroundColor: '#8882D8',
        borderRadius: '100px',
        color: '#fff',
        fontWeight: '800',
        letterSpacing: '0.1em',
        padding: '0.5rem 1rem',
        textTransform: 'uppercase'
    }
});

type ActionTemplateProps = {
    remainingBonusAmount: number;
    setCost: (cost: null | number) => void;
    setCostInput: (costInput: boolean) => void;
    setDate: (date: string) => void;
    setDateInput: (dateInput: boolean) => void;
    setDateSuggestion: (dateSuggestion: string) => void;
    setDescription: (description: string) => void;
    setDescriptionInput: (descriptionInput: boolean) => void;
    setDisplayCostBtns: (displayCostBtns: boolean) => void;
    setDisplayDateBtns: (displayDateBtns: boolean) => void;
    setDisplayDescriptionBtns: (displayDescriptionBtns: boolean) => void;
    setQolMeasure: (qolMeasure: null | number) => void;
    setUseTemplate: (useTemplate: boolean) => void;
    templates: ActionStepTemplate[] | null;
};

const calculateDateSuggestion = (due_at: number) => {
    if (due_at === 0 || !due_at) {
        return 'No due date';
    } else if (due_at === 30) {
        return '1 month';
    } else {
        return `${due_at} days`;
    }
};

const convertDateToString = (due_at: number) => {
    const dateMinusOne = due_at - 1;
    const today = new Date();
    today.setDate(today.getDate() + dateMinusOne);
    const dueDateMinusOne = today.toISOString().split('T')[0];

    return dueDateMinusOne;
};

const ActionTemplate: React.FC<ActionTemplateProps> = ({
    remainingBonusAmount,
    setCost,
    setCostInput,
    setDate,
    setDateInput,
    setDateSuggestion,
    setDescription,
    setDescriptionInput,
    setDisplayCostBtns,
    setDisplayDateBtns,
    setDisplayDescriptionBtns,
    setQolMeasure,
    setUseTemplate,
    templates
}) => {
    const classes = useStyles();

    const [customDescription, setCustomDescription] = useState('');
    const [expandedCount, setExpandedCount] = useState(3);
    const [active, setActive] = useState(false);

    const customTemplate = {
        action_type: 'one_time',
        bonus: 0,
        description: 'Write your own...',
        due_at: 0,
        quality_of_life_measure_id: null
    };

    const handleCustomCardClick = () => {
        setActive(true);
        handleTemplateClick(0, 0, customDescription, null);
    };
    const handleSeeMoreClick = () => {
        setExpandedCount((prevCount) => prevCount + 3);
    };

    const handleTemplateClick = (
        cost: number,
        date: number,
        description: string,
        qolMeasure: null | number
    ) => {
        if (cost && cost > 0) {
            if (cost > remainingBonusAmount) {
                setCost(remainingBonusAmount);
            } else {
                setCost(cost);
            }
            setCostInput(true);
            setDisplayCostBtns(false);
        }

        if (cost === 0) {
            setCost(null);
            setUseTemplate(true);
        }

        if (date && date > 0) {
            setDate(convertDateToString(date));
            setDateInput(true);
            setDisplayDateBtns(false);
            setDateSuggestion(calculateDateSuggestion(date));
        }

        if (description && description.length) {
            setDescription(description);
            setDescriptionInput(true);
            setDisplayDescriptionBtns(false);
        }

        if (qolMeasure) {
            setQolMeasure(qolMeasure);
        }

        setUseTemplate(true);
    };

    return (
        <Card className={classes.actionTemplateCard}>
            <span className={classes.templateTitle}>Create From Template</span>
            <div className={classes.templateContainer}>
                {templates?.slice(0, expandedCount).map((template, index) => (
                    <TemplateCard
                        key={index}
                        onClick={() => {
                            handleTemplateClick(
                                (template.bonus && template.bonus) || 0,
                                (template.due_at && template.due_at) || 0,
                                (template.description.length &&
                                    template.description) ||
                                    '',
                                (template.quality_of_life_measure_id &&
                                    template.quality_of_life_measure_id) ||
                                    null
                            );
                        }}
                        template={template}
                    />
                ))}
                {templates && expandedCount < templates?.length && (
                    <Button
                        aria-controls="action-templates"
                        aria-expanded={expandedCount < templates?.length}
                        className={classes.expandButton}
                        onClick={handleSeeMoreClick}
                    >
                        See More <i className="fal fa-chevron-down" />
                    </Button>
                )}
            </div>
            <div className={classes.customTitle}>
                <span>Custom Action Step</span>
                <span className={classes.divider} />
            </div>
            <TemplateCard
                active={active}
                customDescription={customDescription}
                onClick={handleCustomCardClick}
                setCustomDescription={setCustomDescription}
                template={customTemplate}
            />
        </Card>
    );
};

export default ActionTemplate;
