import { useEffect, useRef, useState } from 'react';

import ReferBody from './components/ReferBody';

import { DeploymentOrgs, DeploymentUsers, Homeless, User } from 'types';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            position: 'relative',
            paddingBottom: '20px',
            marginBottom: '24px',
            zIndex: 5
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            width: '100%',
            zIndex: 6
        },
        modalContent: {
            position: 'relative',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
            margin: '72px 24px 32px 24px',
            maxWidth: '660px',
            overflow: 'auto',
            paddingTop: '48px',
            width: '80%',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        }
    })
);

type ReferMemberProps = {
    deploymentOrgs: DeploymentOrgs[];
    deploymentUsers: DeploymentUsers[];
    handleModalClick: (open: boolean) => void;
    holder: Homeless;
    isReferringMember: boolean;
    referMember: () => Promise<void>;
    referMemberError: boolean | null;
    setReferMemberPopUp: (value: boolean) => void;
    user: User;
};

const ReferMember: React.FC<ReferMemberProps> = ({
    deploymentOrgs,
    deploymentUsers,
    handleModalClick,
    holder,
    isReferringMember,
    referMember,
    referMemberError,
    setReferMemberPopUp,
    user
}) => {
    const classes = useStyles();
    const modalRef = useRef<HTMLDivElement>(null);

    const { id: memberID, mobile_photo, name } = holder;
    const { partner } = user;
    const deployments = partner?.deployments || [];

    const [managerPopup, setManagerPopup] = useState(false);
    const [orgIDs, setOrgIDs] = useState<number>(0);
    const [referralManager, setReferralManager] =
        useState<DeploymentUsers | null>(null);
    const [referralOrg, setReferralOrg] = useState<DeploymentOrgs | null>(null);

    const handleCloseClick = () => {
        setReferralManager(null);
        setReferralOrg(null);
        handleModalClick(false);
    };

    const referBodyProps = {
        deployments,
        deploymentOrgs,
        deploymentUsers,
        isReferringMember,
        managerPopup,
        memberID,
        mobile_photo,
        name,
        orgIDs,
        referMember,
        referMemberError,
        referralManager,
        referralOrg,
        setManagerPopup,
        setOrgIDs,
        setReferMemberPopUp,
        setReferralManager,
        setReferralOrg
    };

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.scrollTo(0, 0);
        }
    });

    return (
        <Fade in={true}>
            <div className={classes.modal}>
                <div
                    className={classes.modalContent}
                    ref={modalRef}
                    style={managerPopup ? { overflow: 'hidden' } : {}}
                >
                    <ReferBody {...referBodyProps} />
                </div>
                <div className={classes.btnContainer}>
                    <Button className={classes.btn} onClick={handleCloseClick}>
                        Close
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

export default ReferMember;
