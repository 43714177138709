import classNames from 'classnames';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        referredTag: {
            position: 'absolute',
            left: '0',
            top: '16px',
            borderRadius: '0 4px 4px 0',
            fontSize: '14px',
            fontWeight: 700,
            letterSpacing: '2px',
            lineHeight: '14px',
            padding: '4px 12px',
            textTransform: 'uppercase',
            zIndex: 1
        },
        referred: {
            backgroundColor: '#FEF3E7',
            color: '#7A4406'
        },
        referralAccepted: {
            backgroundColor: '#E9FBF2',
            color: '#1B9757'
        },
        referralRejected: {
            backgroundColor: '#FEE7EC',
            color: '#A80A2D'
        }
    })
);

type ReferredTagProps = {
    text: string;
};

const ReferredTag: React.FC<ReferredTagProps> = ({ text }) => {
    const classes = useStyles();

    const tagClass = classNames({
        [classes.referredTag]: true,
        [classes.referred]: text === 'referred' || text === 'referred to you',
        [classes.referralAccepted]: text === 'referral accepted',
        [classes.referralRejected]: text === 'referral rejected'
    });

    return <span className={tagClass}>{text}</span>;
};

export default ReferredTag;
