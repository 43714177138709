import CONSTANTS from '../constants/constants';

import { Homeless } from '../../types';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import { isObjectEmpty } from '../../util';

import * as api from '../../api/homelesses';

const createCaseload = () => {
    return {
        type: CONSTANTS.CREATE_CASELOAD
    };
};

export const createMemberCaseload = (memberID: number) => {
    return (dispatch: Function) => {
        dispatch(createCaseload());
        return api
            .createMemberCaseload(memberID)
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then(
                (responseJson: {
                    ctp_caseload: { homeless_id: number; id: number };
                }) => {
                    dispatch(
                        saveMember(responseJson.ctp_caseload.id, memberID)
                    );
                }
            )
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
};

export const getCaseloads = () => {
    return (dispatch: Function) => {
        dispatch(fetchCaseloads());
        return api
            .fetchUserCaseloads()
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { ctp_caseloads: object[] }) => {
                return dispatch(setCaseloads(responseJson.ctp_caseloads));
            })
            .catch((error: any) => {
                dispatch(setCaseloadsError());
                return handleSamaritanApiError(error, dispatch);
            });
    };
};

export const getHomelesses = () => {
    return async (dispatch: Function, getState: Function) => {
        dispatch(fetchHomelesses());

        try {
            const response = await api.fetchHomelesses();

            if (response && response.ok) {
                const resJson = await response.json();
                // const homelesses = resJson.samaritan_members;

                // handleSamaritanApiResponse(response, dispatch);
                dispatch(setHomelesses(resJson.samaritan_members));

                if (getState().user.state === 'success') {
                    dispatch(fetchHomelessesLanguages());
                }
            } else {
                dispatch(setHomelessesError());
            }
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
};

const fetchCaseloads = () => {
    return {
        type: CONSTANTS.GET_CASELOADS
    };
};

function fetchHomelesses() {
    return {
        type: CONSTANTS.GET_HOMELESSES
    };
}

export const fetchHomelessesLanguages = () => {
    return async (dispatch: Function) => {
        try {
            const response = await api.fetchHomelessLanguagePreferences();

            if (response && response.ok) {
                const resJson = await response.json();

                if (resJson.language_preferences) {
                    dispatch(
                        setHomelessesLanguages(resJson.language_preferences)
                    );
                } else {
                    dispatch(setHomelessesLanguages(['english']));
                }
            }
        } catch (e: any) {
            handleSamaritanApiError(
                { message: 'Failed to fetch language preferences' },
                dispatch
            );
        }
    };
};

const removeMember = (caseloadID: number, rejected?: boolean) => {
    return {
        type: CONSTANTS.REMOVE_MEMBER_FROM_MY_MEMBERS,
        payload: { caseloadID, rejected }
    };
};

export const removeMemberFromMyMembers = (
    caseloadID: number,
    rejected?: boolean
) => {
    return (dispatch: Function) => {
        dispatch(removeMember(caseloadID, rejected));
        return api
            .removeMemberFromMyMembers(caseloadID)
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
};

const saveMember = (
    caseloadID: number,
    memberID: number,
    accepted?: boolean
) => {
    return {
        type: CONSTANTS.ADD_MEMBER_TO_MY_MEMBERS,
        payload: { caseloadID, memberID, accepted }
    };
};

export const saveMemberToMyMembers = (
    caseloadID: number,
    memberID: number,
    accepted?: boolean
) => {
    return (dispatch: Function) => {
        dispatch(saveMember(caseloadID, memberID, accepted));
        return api
            .saveMemberToMyMembers(caseloadID)
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .catch((error: any) => {
                return handleSamaritanApiError(error, dispatch);
            });
    };
};

const setCaseloads = (caseload: any) => {
    if (isObjectEmpty(caseload)) {
        caseload.empty = true;
    }
    return {
        type: CONSTANTS.SET_CASELOADS,
        payload: caseload
    };
};

const setCaseloadsError = () => {
    return {
        type: CONSTANTS.SET_CASELOADS_ERROR
    };
};

// const setMembersActive = () => {
//     return {
//         type: CONSTANTS.SET_MEMBERS_ACTIVE
//     };
// }

// const setMembersExited = () => {
//     return {
//         type: CONSTANTS.SET_MEMBERS_EXITED
//     };
// }

function setHomelesses(homeless: any) {
    if (isObjectEmpty(homeless)) {
        homeless.empty = true;
    }
    return {
        type: CONSTANTS.SET_HOMELESSES,
        payload: homeless
    };
}

function setHomelessesError() {
    return {
        type: CONSTANTS.SET_HOMELESSES_ERROR
    };
}

function setHomelessesLanguages(languages: string[]) {
    return {
        type: CONSTANTS.SET_HOMELESSES_LANGUAGES,
        payload: languages
    };
}

export function updateHomelesses(homeless: Homeless) {
    return {
        type: CONSTANTS.UPDATE_HOMELESSES,
        payload: homeless
    };
}

// const sortHomelesses = (homelesses: Homeless[]) => {
//     const activeHomelesses: Homeless[] = [];
//     const deploymentHomelesses: Homeless[] = [];
//     const exitedHomelesses: Homeless[] = [];
//     const orgHomelesses: Homeless[] = [];

//     homelesses.forEach((homeless: Homeless) => {
//         if (homeless.profile_stage === 'EXITED') {
//             exitedHomelesses.push(homeless);
//         } else if (caseloads includes homeless.id && caseload.status === 'ACTIVE') {
//             activeHomelesses.push(homeless);
//         } else if (homeless.profile_stage === 'DEPLOYMENT') {
//             deploymentHomelesses.push(homeless);
//         } else {
//             orgHomelesses.push(homeless);
//         }
//     });

//     return {
//         activeHomelesses,
//         deploymentHomelesses,
//         exitedHomelesses,
//         orgHomelesses
//     };
// };

const updateMemberLanguagePreference = (memberID: number, language: string) => {
    return {
        type: CONSTANTS.UPDATE_HOMELESSES_LANGUAGE_PREFERENCE,
        payload: { memberID, language }
    };
};

export const updateHomelessLanguagePreference = (
    memberID: number,
    language: string
) => {
    return async (dispatch: Function) => {
        try {
            const response = await api.updateUserLanguagePreference(
                memberID,
                language
            );

            if (response && response.ok) {
                dispatch(updateMemberLanguagePreference(memberID, language));
            }
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
};
