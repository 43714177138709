import CONSTANTS from '../constants/constants';

import { DeploymentUsers } from 'types';

import * as api from 'api/deployments';
import { fetchGoalActions } from 'api/goal_actions';

import { addNewGoalAction } from './goal_actions';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

function fetchDeploymentOrgsAction() {
    return {
        type: CONSTANTS.GET_DEPLOYMENT_ORGS
    };
}

function fetchDeploymentUsersAction() {
    return {
        type: CONSTANTS.GET_DEPLOYMENT_USERS
    };
}

function referMemberAction() {
    return {
        type: CONSTANTS.REFER_MEMBER
    };
}

function referMemberFailAction() {
    return {
        type: CONSTANTS.REFER_MEMBER_FAIL
    };
}

function referMemberSuccessAction() {
    return {
        type: CONSTANTS.REFER_MEMBER_SUCCESS
    };
}

export function setDeploymentOrgs(response: object) {
    return {
        type: CONSTANTS.SET_DEPLOYMENT_ORGS,
        payload: response
    };
}

function setDeploymentUsers(response: object) {
    return {
        type: CONSTANTS.SET_DEPLOYMENT_USERS,
        payload: response
    };
}

export function fetchDeploymentOrgsRequest(id: number) {
    return (dispatch: Function) => {
        dispatch(fetchDeploymentOrgsAction());
        return api
            .fetchDeployment(id)
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then(
                (responseJson: {
                    deployment: { relations: { organizations: object[] } };
                }) => {
                    if (
                        responseJson.deployment &&
                        responseJson.deployment.relations
                    ) {
                        return dispatch(
                            setDeploymentOrgs(
                                responseJson.deployment.relations.organizations
                            )
                        );
                    } else {
                        return dispatch(setDeploymentOrgs([]));
                    }
                }
            )
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function fetchDeploymentUsersRequest() {
    return (dispatch: Function) => {
        dispatch(fetchDeploymentUsersAction());
        return api
            .fetchDeploymentUsers()
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { users: DeploymentUsers[] }) => {
                if (responseJson.users) {
                    return dispatch(setDeploymentUsers(responseJson.users));
                } else {
                    return dispatch(setDeploymentUsers([]));
                }
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function referMemberRequest({
    homeless_id,
    organization_id,
    referee_id
}: {
    homeless_id: number;
    organization_id: number;
    referee_id: number;
}) {
    return async (dispatch: Function) => {
        dispatch(referMemberAction());

        try {
            const response = await api.referMember({
                homeless_id,
                organization_id,
                referee_id
            });

            if (response && response.ok) {
                const responseJson = await response.json();
                const {
                    ctp_caseload_referral: { id: caseload_referral_id } = {
                        id: null
                    }
                } = responseJson;

                if (caseload_referral_id) {
                    dispatch(referMemberSuccessAction());

                    try {
                        const goalActionsResponse = await fetchGoalActions(
                            homeless_id
                        );
                        const { goal_actions } =
                            await goalActionsResponse.json();
                        const matchingGoalAction = goal_actions.find(
                            (goalAction: any) =>
                                goalAction.ctp_caseload_referral_id ===
                                caseload_referral_id
                        );

                        if (matchingGoalAction) {
                            dispatch(addNewGoalAction(matchingGoalAction));
                        } else {
                            console.error('No matching goal action found');
                        }
                    } catch {
                        console.error('Failed to fetch goal actions');
                    }
                } else {
                    dispatch(referMemberFailAction());
                    console.error(
                        'Caseload referral ID not found in the response'
                    );
                }
            } else {
                dispatch(referMemberFailAction());
                console.error('Failed to refer member: Invalid response');
            }
        } catch (error) {
            dispatch(referMemberFailAction());
            console.error('Error during referral:', error);
        }
    };
}