import { REACT_APP_ENV } from '../settings';

type HelperObject = {
    object: {
        isObjectEmpty: (object: object) => boolean;
    };
    array: {
        numRange: (start: number, end: number) => number[];
        addToArray: (array: any[], item: any) => any[];
        updateObjectInArray: (
            array: any[],
            identityCallback: (item: any) => boolean,
            key: string,
            value: any
        ) => any[];
        updateObject: (object: object, key: string, value: any) => object;
        removeFromArrayByIndex: (array: any[], i: number) => any[];
        removeFromArrayByValue: (array: any[], value: any) => any[];
        removeObjectFromArrayByValue: (
            array: any[],
            objectKey: string,
            value: any
        ) => any[];
    };
    date: {
        getMonth: (i: number) => string;
        dateToFormattedString: (date: Date, seperator?: string) => string;
        dateDiffs: (oldDate: Date, date?: Date) => object;
        formattedDate: (date: Date) => string;
        formatDateMonthDay: (day: string) => string;
    };
    string: {
        capitalizeWord: (word: string) => string;
        emailValid: (text: string) => boolean;
        phoneValid: (text: string) => boolean;
        possessiveSuffix: (name: string) => string;
    };
    number: {
        roundToNth: (number: number, n?: number) => number;
        numToDollars: (num: number) => string | number;
        numberWithCommas: (x: number) => string;
    };
    json: {
        jsonToDate: (json_date: string) => Date;
    };
    sdoh: {
        sortedQuestionIds: number[];
        sdohQuestionMap: { name: string; question_id: number }[];
        sdohCategoryNameByQuestionId: (id: number) =>
            | {
                  name: string;
                  question_id: number;
              }
            | undefined;
        friendlyNameForAnswer: (
            determinantRanks: [{ id: string; friendly_name: string }],
            answer: { answer: string }
        ) => string;
    };
};

let Helpers: HelperObject = {
    object: {
        isObjectEmpty: () => false
    },
    array: {
        numRange: () => [],
        addToArray: () => [],
        updateObjectInArray: () => [],
        updateObject: () => ({}),
        removeFromArrayByIndex: () => [],
        removeFromArrayByValue: () => [],
        removeObjectFromArrayByValue: () => []
    },
    date: {
        getMonth: () => '',
        dateToFormattedString: () => '',
        dateDiffs: () => ({}),
        formattedDate: () => '',
        formatDateMonthDay: () => ''
    },
    string: {
        capitalizeWord: () => '',
        emailValid: () => false,
        phoneValid: () => false,
        possessiveSuffix: () => ''
    },
    number: {
        roundToNth: () => 0,
        numToDollars: () => '',
        numberWithCommas: () => ''
    },
    json: {
        jsonToDate: () => new Date()
    },
    sdoh: {
        sortedQuestionIds: [],
        sdohQuestionMap: [],
        sdohCategoryNameByQuestionId: () => ({
            name: '',
            question_id: 0
        }),
        friendlyNameForAnswer: () => ''
    }
};

//
// OBJECTS
//

export function isObjectEmpty(object: object) {
    return Object.keys(object).length === 0;
}

Helpers.object.isObjectEmpty = isObjectEmpty;

//
// ARRAYS
//

export function numRange(start: number, end: number): number[] {
    let range = [];
    for (let i = start; i <= end; i++) {
        range.push(i);
    }
    return range;
}

export function addToArray(array: any[], item: any): any[] {
    return [...array, item];
}

export function updateObjectInArray(
    array: any[],
    identityCallback: (item: any) => boolean,
    key: string,
    value: any
): any[] {
    return array.map((item) => {
        if (!identityCallback(item)) return item;
        return {
            ...(item as object),
            [key]: value
        };
    });
}

export function updateObject(
    object: { [key: string]: any },
    key: string,
    value: any
): object {
    const obj = {
        ...object
    };
    obj[key] = value;
    return obj;
}

export function removeFromArrayByIndex(array: any[], i: number): any[] {
    return array.slice(0, i - 1).concat(array.slice(i, array.length));
}

export function removeFromArrayByValue(array: any[], value: any): any[] {
    return array.filter((item) => item !== value);
}

export function removeObjectFromArrayByValue(
    array: any[],
    objectKey: string,
    value: any
): any[] {
    return array.filter((item) => item[objectKey] !== value);
}

Helpers.array.numRange = numRange;
Helpers.array.addToArray = addToArray;
Helpers.array.updateObjectInArray = updateObjectInArray;
Helpers.array.updateObject = updateObject;
Helpers.array.removeFromArrayByIndex = removeFromArrayByIndex;
Helpers.array.removeFromArrayByValue = removeFromArrayByValue;
Helpers.array.removeObjectFromArrayByValue = removeObjectFromArrayByValue;

//
// DATES
//
export const getMonth = (i: number) => {
    const months: { [key: number]: string } = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    };
    return months[i];
};

export function dateToFormattedString(date: Date, separator: string = '/') {
    if (Object.prototype.toString.call(date) !== '[object Date]') return '';

    let day: string | number = date.getDate();
    let month: string | number = date.getMonth() + 1;
    let year: string | number = date.getFullYear();

    switch (separator) {
        case '/':
            if (day <= 9) day = `0${day}`;
            if (month <= 9) month = `0${month}`;
            return `${month}${separator}${day}${separator}${year}`;
        case '-':
            month = getMonth(month).toString().slice(0, 4);
            year = year.toString().slice(2, 4);
            return `${day}${separator}${month}${separator}${year}`;
        default:
            return date.toString();
    }
}

export function dateDiffs(oldDate: any, date = new Date()) {
    const dateTime = date.getTime();
    const oldDateTime = oldDate.getTime();

    const milliseconds = Math.floor(dateTime - oldDateTime);
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const years = date.getFullYear() - oldDate.getFullYear();
    const months = years * 12 + (date.getMonth() - oldDate.getMonth());

    return {
        milliseconds,
        seconds,
        minutes,
        hours,
        days,
        weeks,
        months,
        years
    };
}

export function formattedDate(date: any) {
    const dateMonth = date.getMonth() + 1;
    const dateDay = date.getDate();
    const dateYear = date.getFullYear();

    return `${getMonth(dateMonth)} ${dateDay}, ${dateYear}`;
}

export function formatDateMonthDay(day: string) {
    if (day) {
        const date = new Date(day);

        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
        });

        return `${formattedDate}`;
    } else {
        return `No Due Date`;
    }
}

Helpers.date.getMonth = getMonth;
Helpers.date.dateToFormattedString = dateToFormattedString;
Helpers.date.dateDiffs = dateDiffs;
Helpers.date.formattedDate = formattedDate;
Helpers.date.formatDateMonthDay = formatDateMonthDay;

//
// STRINGS
//
export function capitalizeWord(word: string) {
    if (typeof word !== 'string') return '';
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function emailValid(text: string) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        text
    );
}

export function phoneValid(text: string) {
    return /^\(?[0-9]{3}\)?[0-9]{3}-?[0-9]{4}$/.test(text);
}

export const possessiveSuffix = (name: string) =>
    name.endsWith('s') ? `${name}'` : `${name}'s`;

Helpers.string.capitalizeWord = capitalizeWord;
Helpers.string.emailValid = emailValid;
Helpers.string.phoneValid = phoneValid;
Helpers.string.possessiveSuffix = possessiveSuffix;

//
// NUMBERS
//
export function roundToNth(number: number, n = 100) {
    return Math.round(number * n) / n;
}

// This function will return the integer version if the decimals are .00
// numToDollars(2.00) => '$2'
// numToDollars(2.11) => '$2.11'
export function numToDollars(num: number) {
    if (isNaN(num)) return num;
    // ensure the number has two decimals
    let number: number | string = roundToNth(num);
    // if the number is .00, then just show an integer with no decimals
    if (Number.parseFloat(num.toString()) === Math.floor(num))
        number = Math.floor(num);
    // if the number is more than 3 digits, make sure commas are added.
    number = `$${numberWithCommas(number)}`;
    // if the number has one decimal, add a 0 to the end
    if (number.toString().indexOf('.') === number.toString().length - 2)
        number = `${number}0`;
    return number;
}

export function numberWithCommas(x: number) {
    let units = parseInt(Math.floor(x).toString());
    let decimals = (x % 1).toFixed(2);

    if (decimals === '0.00') decimals = '';

    return units.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    decimals.toString().slice(1);
}

Helpers.number.roundToNth = roundToNth;
Helpers.number.numToDollars = numToDollars;
Helpers.number.numberWithCommas = numberWithCommas;

//
// JSON
//
export const jsonToDate = (json_date: string) => new Date(json_date);

Helpers.json.jsonToDate = jsonToDate;

// SDOH
const sdohQuestionMapper = (startId: number) => {
    const orderedQuestions = [
        'Housing',
        'Income',
        'Food',
        'Transportation',
        'Clothing',
        'Access to Services(health, legal, job training, etc)',
        'Support System',
        'Mental Health'
    ];

    return orderedQuestions.map((name, index) => ({
        name,
        question_id: index + startId
    }));
};

const prodSortedQuestionIds = [9, 10, 11, 12, 13, 14, 15, 16]; // prod
const stagingSortedQuestionIds = [16, 17, 18, 19, 20, 21, 22, 23]; // staging
export const sortedQuestionIds =
    REACT_APP_ENV === 'staging'
        ? stagingSortedQuestionIds
        : prodSortedQuestionIds;

export const sdohQuestionMap = sdohQuestionMapper(sortedQuestionIds[0]);

export function sdohCategoryNameByQuestionId(id: number) {
    return sdohQuestionMap.find((q) => q.question_id === id);
}

export const friendlyNameForAnswer = (
    determinantRanks: [{ id: string; friendly_name: string }],
    answer: { answer: string }
) => {
    const rank = determinantRanks.filter(
        (determinantRank) =>
            parseInt(determinantRank.id) === parseInt(answer.answer)
    )[0];
    return rank ? rank.friendly_name : 'n/a';
};

export const getHolderName = (name: string) => {
    const possessiveS = name.slice(-1) === 's' ? "'" : "'s";

    return name + possessiveS;
};

Helpers.sdoh.sortedQuestionIds = sortedQuestionIds;
Helpers.sdoh.sdohQuestionMap = sdohQuestionMap;
Helpers.sdoh.sdohCategoryNameByQuestionId = sdohCategoryNameByQuestionId;
Helpers.sdoh.friendlyNameForAnswer = friendlyNameForAnswer;

export default Helpers;
