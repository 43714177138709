import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError, getApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import { submitIntake as submitIntakeToApi } from 'api/intake';
import { submitTouchpointAnswers } from 'api/touchpoint';

import { updateHomelesses } from './homelesses';

export function resetIntake() {
    return {
        type: CONSTANTS.RESET_INTAKE
    };
}

export function decrementIntakeStep(step: number) {
    return {
        type: CONSTANTS.DECREMENT_INTAKE_STEP,
        payload: step ? step : null
    };
}

function getIntakeSubmit() {
    return {
        type: CONSTANTS.GET_INTAKE_SUBMIT
    };
}

function getIntakeTouchpointSubmit() {
    return {
        type: CONSTANTS.GET_INTAKE_TOUCHPOINT_SUBMIT
    };
}

export function incrementIntakeStep(progressWidth: number) {
    return {
        type: CONSTANTS.INCREMENT_INTAKE_STEP,
        payload: progressWidth
    };
}

export function setIntakeBirthDay(birthday: string) {
    return {
        type: CONSTANTS.SET_INTAKE_BIRTH_DAY,
        payload: birthday
    };
}

export function setIntakeDeploymentId(deploymentId: number) {
    return {
        type: CONSTANTS.SET_INTAKE_DEPLOYMENT_ID,
        payload: deploymentId
    };
}

export function setIntakeEmail(email: string) {
    return {
        type: CONSTANTS.SET_INTAKE_EMAIL,
        payload: email
    };
}

function setIntakeError() {
    return {
        type: CONSTANTS.SET_INTAKE_ERROR
    };
}

export function setIntakeGoals(goals: string[]) {
    return {
        type: CONSTANTS.SET_INTAKE_GOALS,
        payload: goals
    };
}

export function setIntakeIcebreaker(icebreaker: string) {
    return {
        type: CONSTANTS.SET_INTAKE_ICEBREAKER,
        payload: icebreaker
    };
}

export function setIntakeInterviewer(interviewer: string) {
    return {
        type: CONSTANTS.SET_INTAKE_INTERVIEWER,
        payload: interviewer
    };
}

export function setIntakeInterviewerId(interviewerId: number) {
    return {
        type: CONSTANTS.SET_INTAKE_INTERVIEWER_ID,
        payload: interviewerId
    };
}

export function setIntakeNeeds(needs: string[]) {
    return {
        type: CONSTANTS.SET_INTAKE_NEEDS,
        payload: needs
    };
}

export function setIntakeOrgId(orgId: number) {
    return {
        type: CONSTANTS.SET_INTAKE_ORG_ID,
        payload: orgId
    };
}

export function setIntakePhoneNo(phoneNo: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PHONE_NUMBER,
        payload: phoneNo
    };
}

export function setIntakePrivateFirstName(privateFirstName: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PRIVATE_FIRST_NAME,
        payload: privateFirstName
    };
}

export function setIntakePrivateFullName(privateFullName: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PRIVATE_FULL_NAME,
        payload: privateFullName
    };
}

export function setIntakePrivateLastName(privateLastName: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PRIVATE_LAST_NAME,
        payload: privateLastName
    };
}

export function setIntakePrivateMiddleName(privateMiddleName: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PRIVATE_MIDDLE_NAME,
        payload: privateMiddleName
    };
}

export function setIntakeProfileName(profileName: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PROFILE_NAME,
        payload: profileName
    };
}

export function setIntakeProfilePicture(profilePic: string) {
    return {
        type: CONSTANTS.SET_INTAKE_PROFILE_PICTURE,
        payload: profilePic
    };
}

export function setIntakeQr(qr: string) {
    return {
        type: CONSTANTS.SET_INTAKE_QR,
        payload: qr
    };
}

export function setIntakeRelevantId(relevantId: number) {
    return {
        type: CONSTANTS.SET_INTAKE_RELEVANT_ID,
        payload: relevantId
    };
}

function setIntakeSubmit(id: number, beaconId: string) {
    return {
        type: CONSTANTS.SET_INTAKE_SUBMIT,
        payload: { id, beaconId }
    };
}

export function setIntakeTouchpointAnswer(questionId: number, answer: string) {
    return {
        type: CONSTANTS.SET_INTAKE_TOUCHPOINT_ANSWER,
        payload: { questionId, answer }
    };
}

function setIntakeTouchpointError() {
    return {
        type: CONSTANTS.SET_INTAKE_TOUCHPOINT_ERROR
    };
}

function setIntakeTouchpointSubmit() {
    return {
        type: CONSTANTS.SET_INTAKE_TOUCHPOINT_SUBMIT
    };
}

export function setViewGuideMessage(messageType: string) {
    return {
        type: CONSTANTS.SET_VIEW_GUIDE_MESSAGE,
        payload: messageType
    };
}

export function submitIntake(_token: string, data: any) {
    return (dispatch: Function, getState: any) => {
        dispatch(getIntakeSubmit());

        const beaconId = getState().intake.intakeBeaconId;

        return submitIntakeToApi(data, beaconId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch); // this function doesn't handle 403's because we have to throw an error inside of a response Promise
            })
            .then((responseJson: any) => {
                if (Object.keys(responseJson).includes('errors')) {
                    dispatch(getApiError(responseJson.errors));
                    dispatch(setIntakeError());
                } else if (
                    Object.keys(responseJson).includes('id') &&
                    Object.keys(responseJson).includes('beacon_id')
                ) {
                    dispatch(
                        setIntakeSubmit(responseJson.id, responseJson.beacon_id)
                    );
                    dispatch(updateHomelesses(responseJson));
                } else {
                    throw new Error('no errors or beacon_id in response');
                }

                return responseJson;
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function submitIntakeTouchpoint(_token: string, data: any) {
    return (dispatch: Function) => {
        dispatch(getIntakeTouchpointSubmit());
        return submitTouchpointAnswers(data)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch); // this function doesn't handle 403's because we have to throw an error inside of a response Promise
            })
            .then((responseJson: any) => {
                if (Object.keys(responseJson).includes('errors')) {
                    dispatch(getApiError(responseJson.errors));
                    dispatch(setIntakeTouchpointError());
                } else if (
                    Object.keys(responseJson.touchpoint).includes('id')
                ) {
                    dispatch(setIntakeTouchpointSubmit());
                } else {
                    throw new Error(
                        'no errors in unsuccessful touchpoint response'
                    );
                }

                return responseJson;
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function toggleIntakeAgreed() {
    return {
        type: CONSTANTS.TOGGLE_INTAKE_AGREED
    };
}
