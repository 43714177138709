import SamaritanFetch from './httpClient';

type SignUpUserProps = {
    email: string;
    password: string;
    passwordConfirmation: string;
    name: string;
    lastName: string;
    orgId: string | Blob;
    joinCode: string;
    phoneNumber: string;
};

export const fetchLanguagePreferences = () => {
    return SamaritanFetch.v3('/users/language_preferences');
};

export const fetchUserData = (id: number) => {
    return SamaritanFetch.v3(`/users/${id}`);
};

export const signUpNewUser = ({
    email,
    password,
    passwordConfirmation,
    name,
    lastName,
    orgId,
    joinCode,
    phoneNumber
}: SignUpUserProps) => {
    const form = new FormData();
    form.append('user[email]', email);
    form.append('user[password]', password);
    form.append('user[password_confirmation]', passwordConfirmation);
    form.append('user[name]', name);
    form.append('user[lastname]', lastName);
    form.append('user[organization_id]', orgId);
    form.append('user[join_code]', joinCode);
    form.append('user[phone_number]', phoneNumber);

    return SamaritanFetch.v2.post('/non_profits', {
        body: form
    });
};

export const updateUserGuideSetting = (
    userId: number,
    isShowingIntakeGuides: boolean
) => {
    return SamaritanFetch.v2.put(`/users/${userId}`, {
        body: JSON.stringify({
            is_showing_intake_guides: isShowingIntakeGuides
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const updateUserLanguageSetting = (
    userId: number,
    languagePreference: string
) => {
    return SamaritanFetch.v2.put(`/users/${userId}`, {
        body: JSON.stringify({
            language_preference: languagePreference
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
};
